import { BiUserCircle } from 'react-icons/bi';
import { RiLogoutCircleRLine } from 'react-icons/ri';

import { useAuth } from '@/engine/hooks/useAuth';
import { splitAndReduceText } from '@/modules/utils/splitAndReduceText';

export const UserInfo = (): JSX.Element => {
  const { user, logOut } = useAuth();

  return (
    <div className="flex items-center select-none">
      <BiUserCircle size="1.9rem" color="#009B54" />

      <span className="hidden flex-shrink-0 ml-1 font-400 w-max sm:block">
        {splitAndReduceText(user?.full_name ?? 'Carregando...', 1)}
      </span>

      <div className="mx-4 h-8 w-px bg-floor-black bg-opacity-20" />

      <button
        onClick={logOut}
        type="button"
        className="bg-floor-green flex items-center justify-center rounded-full"
        style={{
          width: '1.9rem',
          height: '1.9rem',
        }}
      >
        <RiLogoutCircleRLine className="text-floor-white" />
      </button>
    </div>
  );
};
