import { Copyright } from './atoms/Copyright';
import { Links } from './atoms/Links';
import { Main } from './atoms/Main';

export const Footer = (): JSX.Element => (
  <footer className="py-8 bg-floor-dark-600 text-white">
    <div className="bowl-content">
      <Main />
      <Links />
      <Copyright />
    </div>
  </footer>
);
