import { useRouter } from 'next/router';
import { useEffect, ReactNode } from 'react';

import { useAuth } from '@/engine/hooks/useAuth';

export interface LoggedProps {
  children: ReactNode;
}

export function Logged({ children }: LoggedProps): ReactNode {
  const router = useRouter();
  const { ready, isAuthenticated } = useAuth();

  useEffect(() => {
    if (!ready || isAuthenticated) {
      return;
    }

    router.push('/login');
  }, [ready, isAuthenticated, router]);

  return children;
}
