interface LinkType {
  href: string;
  title: string;
}

export const links: LinkType[] = [
  {
    title: 'Inicio',
    href: '/',
  },
  {
    title: 'Produtos',
    href: '/products',
  },
  {
    title: 'Urbano Norte',
    href: 'https://urbanonorte.app/',
  },
];
