import type { ReactNode } from 'react';

import { Footer } from '@/screen/components/common/Footer';
import { Header } from '@/screen/components/common/Header';

export interface DefaultLayoutProps {
  children: ReactNode;
}

export function DefaultLayout({ children }: DefaultLayoutProps): JSX.Element {
  return (
    <div className="flex w-full min-h-screen flex-col justify-between">
      <Header />

      <main className="flex-grow flex flex-col w-full">{children}</main>

      <Footer />
    </div>
  );
}
