import {
  useMemo,
  createElement,
  ReactElement,
  ComponentType as ReactComponentType,
} from 'react';

import type { ComponentType, WrapComponent } from '../types';

export interface CreateWrapConfig {
  child: ComponentType;
  components: ComponentType[];
}

export function createWrapComponent({
  child,
  components,
}: CreateWrapConfig): WrapComponent {
  function Component(props: any): ReactElement {
    const renderedElementsTree = useMemo(
      () =>
        components.reduce(
          (children, component) =>
            createElement(component as ReactComponentType, {}, children),
          createElement(child as ReactComponentType, props),
        ),
      [props],
    );

    return renderedElementsTree;
  }

  Component.displayName = 'WrapComponent';

  return Component as WrapComponent;
}
