import { createWrapComponent } from './resources/createWrapComponent';
import { NoopLayout } from './resources/NoopLayout';
import type { ChildType, WrapComponent, ComponentType } from './types';

export interface WrapOptions {
  layout?: ComponentType;
  providers?: ComponentType[];
  components?: ComponentType[];
}

export function wrap<T extends ChildType>(
  child: T,
  options: WrapOptions,
): WrapComponent {
  const { layout = NoopLayout, providers = [], components = [] } = options;

  return createWrapComponent({
    child,
    components: [...components, layout, ...providers],
  });
}
