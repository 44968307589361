import { mergeClassNames } from '@hitechline/reactools';
import type { ProductDocument } from '@hitechline/urbanonorte-types/dtos/franchisee/api';
import { useMemo } from 'react';
import { AiOutlinePicture } from 'react-icons/ai';

import styles from './styles.module.css';

export interface ProductImageProps {
  product: ProductDocument;
}

export const ProductImage = ({ product }: ProductImageProps): JSX.Element => {
  const element = useMemo(() => {
    const firstImage = product.images[0];

    if (firstImage) {
      return (
        <div className={styles['image-container']}>
          <img
            src={firstImage}
            alt={product.name}
            className="absolute block w-full h-full object-cover max-w-full max-h-full top-0 bottom-0 left-0 right-0"
          />
        </div>
      );
    }

    return <AiOutlinePicture className={styles.icon} />;
  }, [product]);

  return (
    <div className={mergeClassNames('product-image', styles.wrapper)}>
      {element}
    </div>
  );
};
