import { mergeClassNames } from '@hitechline/reactools';
import { useState, useCallback, useMemo } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';

import { links } from '@/means/data/header';
import { Link } from '@/screen/components/forward/Link';
import { HeaderButton } from '@/screen/components/ui/HeaderButtons';
import { BlockUIStyle } from '@/screen/styles/BlockUIStyle';

import styles from './styles.module.css';

export const Mobile = (): JSX.Element => {
  const [dropdownVisible, updateDropdownVisible] = useState(false);

  const closeDropdown = useCallback(() => {
    updateDropdownVisible(false);
  }, []);

  const handleDropdown = useCallback(() => {
    updateDropdownVisible(oldValue => !oldValue);
  }, []);

  const dropdown = useMemo(
    () => (
      <div className={styles.dropdown}>
        <div className="overflow-y-auto justify-center pretty-scrollbar bowl-content py-6 flex flex-col h-full items-center">
          <nav className="flex flex-col align-center space-y-14 text-center text-xl font-bold">
            {links.map(({ href, title }) => (
              <Link key={href} href={href} onClick={closeDropdown}>
                {title}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    ),
    [closeDropdown],
  );

  return (
    <div
      className={mergeClassNames(styles.wrapper, {
        'dropdown-visible': dropdownVisible,
      })}
    >
      <div className="bowl-content flex justify-between h-full items-center">
        <button type="button" onClick={handleDropdown}>
          <GiHamburgerMenu size="1.9rem" color="#009B54" />
        </button>

        <HeaderButton />
      </div>

      {dropdownVisible && (
        <>
          {dropdown}
          <BlockUIStyle />
        </>
      )}
    </div>
  );
};
