import { BiUserCircle } from 'react-icons/bi';

import { Link } from '@/screen/components/forward/Link';

export const LoginButton = (): JSX.Element => (
  <Link href="/login" className="flex items-center">
    <BiUserCircle size="1.9rem" color="#009B54" />

    <span className="ml-2 font-400 w-max">Entrar</span>
  </Link>
);
