import { ShimmerBox } from '@/screen/styles/ShimmerBox';

export const ProductCardShimmer = (): JSX.Element => (
  <div className="w-full rounded-t-xl border border-floor-black border-opacity-10 overflow-hidden shadow-sm hover:shadow-2xl">
    <div className="bg-floor-grey-200">
      <ShimmerBox className="w-full h-40 p-1" />
    </div>

    <section className="flex flex-col items-start p-3">
      <ShimmerBox className="mt-2 w-3/4 h-8 p-1" />
      <ShimmerBox className="mt-2 w-1/6 h-8 p-1" />
      <ShimmerBox className="mt-1 w-2/6 h-8 p-1" />
    </section>
  </div>
);
