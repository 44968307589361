// import Logo from '@means/assets/png/logo.png';
import { links } from '@/means/data/header';
import { Link } from '@/screen/components/forward/Link';
import { HeaderButton } from '@/screen/components/ui/HeaderButtons';

import styles from './styles.module.css';

export const Desktop = (): JSX.Element => (
  <div className={styles.wrapper}>
    <div className="bowl-content py-5 flex justify-between items-center">
      <nav className="space-x-5 font-medium">
        {links.map(({ href, title }) => (
          <Link
            key={href}
            href={href}
            className="text-black hover:text-green-200 duration-300"
          >
            {title}
          </Link>
        ))}
      </nav>

      {/* <Link href="/" className="flex items-center justify-center">
        <img
          src={Logo.src}
          alt="Logo - Urbano Institucional"
          className="w-16 absolute"
        />
      </Link> */}

      <div>
        <HeaderButton />
      </div>
    </div>
  </div>
);
