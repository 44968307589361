export const Main = (): JSX.Element => (
  <div>
    <h1 className="font-medium font-600 text-favorite">LOJA URBANO NORTE</h1>

    <p className="text-sm mt-2">
      URBANO NORTE TECNOLOGIA LTDA - 29.981.580/0001-27
    </p>

    <p className="text-sm">Rua Guiana, 3309 - Embratel, Porto Velho - RO</p>
  </div>
);
