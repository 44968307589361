// import Str from '@supercharge/strings';
import type { ProductDocument } from '@hitechline/urbanonorte-types/dtos/franchisee/api';
import { useMemo } from 'react';

import { Link } from '@/screen/components/forward/Link';
import { ProductImage } from '@/screen/components/ui/ProductImage';

import styles from './styles.module.css';

export interface ProductCardProps {
  data: ProductDocument;
}

export const ProductCard = ({ data }: ProductCardProps): JSX.Element => {
  const price = useMemo(
    () =>
      new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(data.sale_price),
    [data.sale_price],
  );

  // const _tags = useMemo(
  //   () =>
  //     data.tags.map(tag => (
  //       <span
  //         key={tag}
  //         className="text-xs px-1 font-bold bg-favorite text-white"
  //       >
  //         {Str(tag).title().get()}
  //       </span>
  //     )),
  //   [data.tags],
  // );

  return (
    <Link
      href="/products/[slug]"
      as={`/products/${data.slug}`}
      className="w-full max-w-sm rounded-xl overflow-hidden shadow-sm hover:shadow-2xl border border-opacity-30 border-favorite"
    >
      <div className="p-3">
        <div className={styles['image-container']}>
          <ProductImage product={data} />
        </div>

        <div className="w-full my-6 h-px bg-opacity-10 bg-black" />

        <section className="flex flex-col items-start font-sans">
          <h1 className="text-xl font-bold">{data.title}</h1>

          <div className="mt-2">
            {/* <div className="select-none flex flex-wrap gap-1">{tags}</div> */}

            <p className="text-base font-semibold text-gray-600">{price}</p>
          </div>
        </section>
      </div>
    </Link>
  );
};
