import { useMemo } from 'react';
import { MdStore } from 'react-icons/md';

import { useAuth } from '@/engine/hooks/useAuth';
import { useCart } from '@/engine/hooks/useCart';

import { LoginButton } from './atoms/LoginButton';
import { UserInfo } from './atoms/UserInfo';

export const HeaderButton = (): JSX.Element => {
  const { isAuthenticated } = useAuth();
  const { checkout } = useCart();

  const cartSize = useMemo(() => {
    if (checkout?.items) {
      return checkout.items.length;
    }

    return 0;
  }, [checkout?.items]);

  return (
    <div className="flex space-x-4">
      <a className="flex items-center" href="/cart">
        <MdStore size="1.9rem" color="#009B54" />

        <span className="font-medium ml-1 font-400 w-max">{cartSize}</span>
      </a>

      {isAuthenticated ? <UserInfo /> : <LoginButton />}
    </div>
  );
};
